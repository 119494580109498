import React from 'react'
import {BsLinkedin } from 'react-icons/bs'
import { BsGithub } from "react-icons/bs";
import { BsGooglePlay } from "react-icons/bs";

const HeaderSocial = () => {
  return (
    <div className='header_socials'>
        <a href="https://www.linkedin.com/in/monirul-islam-price-2342b9b0/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
        <a href="https://github.com/mniprince" target="_blank" rel="noreferrer"><BsGithub/></a>
        <a href="https://play.google.com/store/apps/dev?id=5405265530863735793" target="_blank" rel="noreferrer"><BsGooglePlay/></a>
    </div>
  )
}

export default HeaderSocial