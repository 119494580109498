import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from "react-icons/fa";
import {FiUsers} from "react-icons/fi";
import {VscFolderLibrary} from "react-icons/vsc";

const about = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className='container about_container'>
      <div className='about_me'>
        <div className='about_me_image'>
          <img src={ME} alt='about'/>
        </div>
      </div>

      <div className="about_content">
      <div className="about_cards">
        <article className='about_card'>
          <FaAward className='about_icon'/>
          <h5>Experiance</h5>
          <small>2+ years Working</small>

        </article>

        <article className='about_card'>
          <FiUsers className='about_icon'/>
          <h5>Clients</h5>
          <small>200+ Wolrdwide</small>

        </article>

        <article className='about_card'>
          <VscFolderLibrary className='about_icon'/>
          <h5>Projects</h5>
          <small>50+ Completed</small>

        </article>
      </div>
      <p>
      I'm a skilled developer proficient in Android native app development and Laravel for website, Portfolio, Ecommerce. I craft seamless digital experiences with precision and efficiency.
      </p>
      
      </div>
      </div>
    </section>
  )
}

export default about