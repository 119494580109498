import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skiils I Have</h5>
      <h2>My Experiance</h2>
      <div className="container ecperience_container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>HTML</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>CSS</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>BootStrap</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>Tailwind</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>React</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

          </div>
        </div>
          {/* End Frontend */}
        <div className="experience_backend">
        <h3>Backend Development</h3>
          <div className="experience_content">
              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>NodeJs</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>Mysql</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>Mongodb</h4>
              <small className='text-light'>Intermediate</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>PHP/Laravel</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>API</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

              <article className='experience_details'>
              <BsPatchCheckFill className="experience_datails_icon"/>
              <div>
              <h4>Firebase</h4>
              <small className='text-light'>Experienced</small>
              </div>
              </article>

          </div>
        </div>
      </div>
      </section>
  )
}

export default Experience