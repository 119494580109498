import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'


const data=[
  {
    id:1,
    image:IMG1,
    title:'Laravel Ecommerce',
    github:'https://github.com/mniprince',
    demo:'https://www.gomamu.com/'
  },
  {
    id:2,
    image:IMG2,
    title:'Laravel Ecommerce',
    github:'https://github.com/mniprince',
    demo:'https://ghoriwalabd.com/'
  },
  {
    id:3,
    image:IMG3,
    title:'Laravel Ecommerce',
    github:'https://github.com/mniprince',
    demo:'https://ezadubd.com/'
  },
  {
    id:4,
    image:IMG4,
    title:'Android Native App',
    github:'https://github.com/mniprince',
    demo:'https://play.google.com/store/apps/details?id=com.mniprince.abritti'
  },
  {
    id:5,
    image:IMG5,
    title:'Android Native App',
    github:'https://github.com/mniprince',
    demo:'https://play.google.com/store/apps/details?id=com.jovialway.abrittirkobita'
  },
  {
    id:6,
    image:IMG6,
    title:'Laravel Reseller',
    github:'https://github.com/mniprince',
    demo:'https://kintachireseller.com/'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">

        {
          data.map(({id, image, title, github, demo})=>{
            return(
        <article key={id} className='portfolio_item'>
          <div className="portfolio_item_image">
            <img src={image} alt={title} />
          </div>
            <h3>{title}</h3>
            <div className="portfolio_item_cta">
            <a href={github} className='btn' target='_blank' rel='noreferrer'>GitHub</a>
            <a href={demo} className='btn btn-primary' target='_blank' rel='noreferrer'>Live view</a>
            </div>
        </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio