import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {BsLinkedin } from 'react-icons/bs'
import { BsGithub } from "react-icons/bs";
import { BsGooglePlay } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>MNIPRINCE</a>
      <ul className='parmalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experiance</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonial">Testimonial</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer_socials">
      <a href="https://www.facebook.com/vrantobila" target="_blank" rel="noreferrer"><FaFacebookF/></a>
      <a href="https://www.linkedin.com/in/monirul-islam-price-2342b9b0/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
      <a href="https://github.com/mniprince" target="_blank" rel="noreferrer"><BsGithub/></a>
      <a href="https://play.google.com/store/apps/dev?id=5405265530863735793" target="_blank" rel="noreferrer"><BsGooglePlay/></a>
      </div>

      <div className="footer_copyright">
        <small>
          &copy;Monirul Islam Prince. All Rights reserved.
        </small>
      </div>
    </footer>
  )
}

export default Footer