import React from 'react'
import './contact.css'
import { HiOutlineMailOpen } from "react-icons/hi";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_m4y2nd6', 'template_xhwucmi', form.current, {
        publicKey: 'FFgZS5703uppxxBrV',
      })
      .then(
        () => {
          toast.info('Message Sent Successfully!', {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        },
        (error) => {
          toast.error("Message Not Sent, Try WhatsApp !");
        },
      );
      e.target.reset();
      
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
      <div className="contact_options">

        <article className="contact_option">
        <HiOutlineMailOpen className='contact_option_icon'/>
          <h4>Email</h4>
          <h5>mniprince@gmail.com</h5>
          <a href='mailto:mniprince@gmail.com' >Send A Message</a>
        </article>

        <article className="contact_option">
        <RiMessengerLine className='contact_option_icon'/>
          <h4>Messenger</h4>
          <h5>Contact Via Messenger</h5>
          <a href='https://m.me/vrantobila' target="_blank" rel="noreferrer">Send A Message</a>
        </article>

        <article className="contact_option">
        <BsWhatsapp className='contact_option_icon'/>
          <h4>WhatsApp</h4>
          <h5>01711368494</h5>
          <a href='//api.whatsapp.com/send?phone=+8801711368394&text=Hello' target="_blank" rel="noreferrer">Send A Message</a>
        </article>
       
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your Nome" required/>
        <input type="email" name="email" placeholder="Your Email" required/>
        <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>

      </form>
      <ToastContainer />

      </div>
    </section>
  )
}

export default Contact