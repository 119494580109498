import React from 'react'
import './hosting.css'
import AVTRH from '../../assets/exon.png'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Hosting = () => {
  return (
    <section id='testimonial'>
      <h5>Sugest For Hosting</h5>
      <h2>Best Hosting For Clients</h2>
      <div className="container testimonial_container">
      <div className='hosting' >
              <div className="h_avatar">
                <img src={AVTRH} alt="Hosting"/>
              </div>
              <h5 className='client_name'>BDIX Hosting For Bangladeshi Users</h5>
              <small className='hosting_review'>
                I have lot of clients and I suggest them to use BDIX hosting from ExonHost. 90% of my Clients are using BDIX from ExonHost. Faster and light speed hosting and great support.
                </small>
                <a href="https://my.exonhost.com/affiliate?aff=4116" rel="noreferrer" target="_blank" className='btn'>Buy Hosting</a>
                </div>
                </div>
    </section>
  )
}

export default Hosting