import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services_container">
        <artical className="service">
          <div className="service_head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service_list_icon'/>
              <p>Semantic structures for accessibility and SEO improvement.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Responsive design using media queries for various devices.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>DOM manipulation for dynamic content updates.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Customized styles and components matching design requirements.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Responsive design utilities for adaptive layouts creation.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>State and data flow management with React capabilities.</p>
            </li>

           </ul>
        </artical>
        {/* End of UI/UX */}
        <artical className="service">
          <div className="service_head">
            <h3>Web Development</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service_list_icon'/>
              <p>Integration of technologies to build dynamic web applications.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Emphasis on scalability, security, and performance optimization.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>PDO or MySQLi for database connectivity.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Virtual DOM ensures efficient rendering and optimization.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Relational database management system for data storage.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>NoSQL database for flexible and scalable data storage.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>JavaScript runtime for building scalable server-side applications.</p>
            </li>
          </ul>
        </artical>
        {/* End of Web Development */}
        <artical className="service">
          <div className="service_head">
            <h3>Mobile Application</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service_list_icon'/>
              <p>Create native mobile apps for Android platform users.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Integrate Firebase for real-time database and authentication.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Develop RESTful APIs for seamless communication between components.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Develop admin panel using PHP/Laravel for backend managemen</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Utilize OAuth or JWT for token-based authentication.</p>
            </li>

            <li>
              <BiCheck className='service_list_icon'/>
              <p>Ensure scalability and performance optimization for seamless user experience.</p>
            </li>

          </ul>
        </artical>
        {/* End of App Development */}
      </div>
    </section>
  )
}

export default Services