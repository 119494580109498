import React from 'react'
import './testimonial.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data=[
  {
    id:1,
    image:AVTR1,
    name:'Shykh Seraj',
    review:'Monirul Islam Prince\'s exceptional talent, dedication, and professionalism consistently elevate our Android Mobile Application, Interactive Screen app, surpassing expectations. I highly recommend him for his innovative smart solutions. Highly recommended!'
  },
  {
    id:2,
    image:AVTR2,
    name:'Jahidul Islam',
    review:'Monirul Islam Prince spearheaded our ecommerce project with unmatched expertise and dedication. His strategic insights and flawless execution resulted in a successful and seamless online shopping experience. Highly recommended!'
  },
  {
    id:3,
    image:AVTR3,
    name:'Bithi Akhter',
    review:'Monirul Islam Prince\'s expertise in Laravel and ecommerce culminated in an exceptional landing page. His attention to detail and seamless integration enhanced user experience, driving engagement and conversions. Highly recommended!'
  },
  {
    id:4,
    image:AVTR4,
    name:'Bahar Uddin',
    review:'Monirul Islam Prince\'s mastery of Laravel brought our watch ecommerce site to life. His precision in design and functionality ensured a seamless shopping experience, elevating our brand online. Truly remarkable work and Highly recommended him!'
  },
]

const Testimonial = () => {
  return (
    <section id='testimonial'>
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonial_container"  
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}>

      {
          data.map(({id, image, name, review})=>{
            return(
            <SwiperSlide key={id} className='testimonial' >
              <div className="client_avatar">
                <img src={image} alt={name}/>
              </div>
              <h5 className='client_name'>{name}</h5>
              <small className='client_review'>
                {review}
                </small>
            </SwiperSlide>
              )
            })
          }

      </Swiper>
    </section>
  )
}

export default Testimonial